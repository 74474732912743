<template>
  <div>
    <div class="bg-white max-w-screen w-full grid grid-cols-1 md:grid-cols-12 border">
      <div class="secondary sm:col-span-12 md:col-span-4 text-white hidden-sm-and-down">
        <v-img :src="require('@/assets/images/pages/person-client.png')" cover alt="Connect and socialize"
          class="h-full"></v-img>
      </div>

      <div class="form-enterprise sm:col-span-12 md:col-span-8 mx-auto">
        <h3 class="text-3xl font-extrabold">
          <!-- <h3 class="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight"> -->
          {{ stagelevel === 2 ? 'Submit the new' : 'Create new' }} <span class="secondary--text"> Enterprise</span>
        </h3>
        <p class="mt-4 text-gray-200">
          Nice to create new enterprise! Enter basic informations..
        </p>

        <v-form class="form w-full" v-if="stagelevel === 1">
          <div class="grid grid-cols-1 md:grid-cols-12">
            <div class="sm:col-span-12 md:col-span-6 md:mr-2 text-white">
              <div class="form-item">
                <label class="">
                  Enterprise Name
                </label>
                <v-text-field v-model="name" type="text" class="form-input" placeholder="Your Name"
                  :error-messages="nameErrors.value" clearable :hide-details="nameErrors.isvalid" hint="ddd"
                  @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
              </div>

              <div class="form-item">
                <label class="">
                  Enterprise type
                </label>
                <select v-model="enterprise_type_id" class="form-input" placeholder="">
                  <option value="" disabled selected hidden>Enterprise type</option>
                  <option v-for="t_enterprise in typedata" :value="t_enterprise">{{ t_enterprise }}</option>
                </select>
              </div>

              <div class="form-item">
                <label class="">
                  Annual turnover
                </label>
                <select v-model="annual_turnover" class="form-input" placeholder="Type of company">
                  <option value="" disabled selected hidden>Annual turn over</option>
                  <option v-for="i_turnover in items_turnover" :value="i_turnover">{{ i_turnover }}</option>
                </select>
              </div>
              <div class="form-item">
                <label class="">
                  Number of employees
                </label>
                <select v-model="num_employee" class="form-input" placeholder="Number of employees">
                  <option value="" disabled selected hidden>Pick number of employees</option>
                  <option v-for="num_emp in categories" :value="num_emp" selected>{{ num_emp }}</option>
                </select>
              </div>
              <div class="form-item">
                <label class="">
                  Email address
                </label>
                <v-text-field v-model="email" :hide-details="emailErrors.isvalid" class="form-input"
                  placeholder="contact@spacekola.org" type="email" :error-messages="emailErrors.value"
                  @input="$v.email.$touch()" @blur="$v.email.$touch()" clearable>
                </v-text-field>
              </div>

              <div class="form-item">
                <label class="">
                  Enterprise description
                </label>
                <textarea v-model="about" class="form-input" placeholder="Your Name" />
              </div>
            </div>

            <div class="sm:col-span-12 md:col-span-6 text-white">
              <div class="form-item">
                <label class="">
                  Logo
                </label>
                <div class="relative border-2 border-gray-300 border-dashed rounded-lg p-4" id="dropzone"
                  @click="$refs.ref_logo_img.click()">
                  <input ref="ref_logo_img" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" :hidden="true"
                    @change="onProfilChange" />
                  <v-avatar size="100" rounded="0" @click="$refs.ref_profile_img.click()" v-if="userlogo">

                    <v-img :src="userlogo ? userlogo : ''" alt="" size="100" class="w-100" cover>
                    </v-img>
                  </v-avatar>
                  <div class="text-center" v-else>
                    <v-icon size="60" color="secondary"> {{ icons.mdiCamera }}</v-icon>
                    <h3 class="mt-2 text-sm font-medium text-gray-900">
                      <label for="file-upload" class="relative cursor-pointer">
                        <span>Drag and drop</span>
                        <span class="text-indigo-600"> or browse </span>
                        <span>the logo</span>
                      </label>
                    </h3>
                    <p class="mt-1 text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-item">
                <label class="">
                  Phone number
                </label>
                <vue-phone-number-input v-model="phonenumber" @update="onUpdate" :default-country-code="'CM'" class="">
                </vue-phone-number-input>
              </div>
              <span class="error--text text-caption" v-if="phoneErrors.isvalid === false">{{
                phoneErrors.value
              }}</span>


              <div class="form-item">
                <label class="">
                  County location
                </label>
                <v-select v-model="selectedCountry" dense :items="countries" item-text="name" item-value="id"
                  class="form-input" virtual hide-details>
                </v-select>
              </div>
              <div class="form-item">
                <label class="">
                  Website
                </label>
                <v-text-field v-model="website" dense hide-details placeholder="www.spacekola.org"
                  class="form-input"></v-text-field>
              </div>

              <div class="form-item">
                <label class="">
                  Address
                </label>
                <v-text-field v-model="address" dense hide-details placeholder="Company's adress"
                  class="form-input"></v-text-field>
              </div>
            </div>

          </div>

          <!-- <div class="mb-1 mx-4">
            <div class="form-item">
              <label class="">
                Enterprise Name
              </label>
              <v-text-field v-model="name" type="text" class="form-input" placeholder="Your Name"
                :error-messages="nameErrors.value" clearable :hide-details="nameErrors.isvalid" hint="ddd"
                @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
            </div>

            <div class="form-item">
              <label class="">
                Enterprise type
              </label>
              <select v-model="enterprise_type_id" class="form-input" placeholder="">
                <option value="" disabled selected hidden>Enterprise type</option>
                <option v-for="t_enterprise in typedata" :value="t_enterprise">{{ t_enterprise }}</option>
              </select>
            </div>

            <div class="form-item">
              <label class="">
                Annual turnover
              </label>
              <select v-model="annual_turnover" class="form-input" placeholder="Type of company">
                <option value="" disabled selected hidden>Annual turn over</option>
                <option v-for="i_turnover in items_turnover" :value="i_turnover">{{ i_turnover }}</option>
              </select>
            </div>

            <div class="form-item">
              <label class="">
                Number of employees
              </label>
              <select v-model="num_employee" class="form-input" placeholder="Number of employees">
                <option value="" disabled selected hidden>Pick number of employees</option>
                <option v-for="num_emp in categories" :value="num_emp" selected>{{ num_emp }}</option>
              </select>
            </div>

            <div class="form-item">
              <label class="">
                Enterprise description
              </label>
              <textarea v-model="about" class="form-input" placeholder="Your Name" />
            </div>
          </div>
          <div class="mb-1 mx-4">

            <div class="form-item">
              <label class="">
                Email address
              </label>
              <v-text-field v-model="email" :hide-details="emailErrors.isvalid" class="form-input"
                placeholder="contact@spacekola.org" type="email" :error-messages="emailErrors.value"
                @input="$v.email.$touch()" @blur="$v.email.$touch()" clearable>
              </v-text-field>
            </div>
            <div class="form-item">
              <label class="">
                Phone number
              </label>
              <vue-phone-number-input v-model="phonenumber" @update="onUpdate" :default-country-code="'CM'" class="">
              </vue-phone-number-input>
            </div>
            <span class="error--text text-caption" v-if="phoneErrors.isvalid === false">{{
              phoneErrors.value
              }}</span>

            <div class="form-item">
              <label class="">
                Webdite
              </label>
              <v-text-field v-model="website" outlined dense placeholder="www.spacekola.org" hide-details class="mb-2">
              </v-text-field>
            </div>

            <div class="form-item">
              <label class="">
                Email address
              </label>
              <v-select v-model="selectedCountry" dense :items="countries" item-text="name" item-value="id"
                class="form-input" virtual hide-details>
              </v-select>
            </div>

            <div class="form-item">
              <label class="">
                Address
              </label>
              <v-text-field v-model="address" dense hide-details placeholder="Company's adress"
                class="form-input"></v-text-field>
            </div>

          </div> -->
        </v-form>

        <!-- Validation form -->

        <v-form class="form w-full" v-if="stagelevel === 2">
          <div class="grid grid-cols-1 md:grid-cols-12">
            <div class="sm:col-span-12 md:col-span-6 md:mr-2 text-white">
              <div class="form-item">
                <label class="">
                  Logo
                </label>
                <div class="relative border-2 border-gray-300 border-dashed rounded-lg p-4">
                  <v-avatar size="100" rounded="0" @click="$refs.ref_profile_img.click()" v-if="userlogo">
                    <v-img :src="userlogo ? userlogo : ''" alt="" size="100" class="w-100" cover>
                    </v-img>
                  </v-avatar>
                </div>
              </div>

              <div class="form-item">
                <label class="">
                  Enterprise Name
                </label>
                <v-text-field v-model="name" type="text" class="form-input" placeholder="Your Name"
                  :error-messages="nameErrors.value" clearable :hide-details="nameErrors.isvalid" hint="ddd"
                  @input="$v.name.$touch()" @blur="$v.name.$touch()" disabled></v-text-field>
              </div>

              <div class="form-item">
                <label class="">
                  Enterprise type
                </label>
                <select v-model="enterprise_type_id" class="form-input" placeholder="" disabled>
                  <option value="" disabled selected hidden>Enterprise type</option>
                  <option v-for="t_enterprise in typedata" :value="t_enterprise">{{ t_enterprise }}</option>
                </select>
              </div>

              <div class="form-item">
                <label class="">
                  Annual turnover
                </label>
                <select v-model="annual_turnover" class="form-input" placeholder="Type of company" disabled>
                  <option value="" disabled selected hidden>Annual turn over</option>
                  <option v-for="i_turnover in items_turnover" :value="i_turnover">{{ i_turnover }}</option>
                </select>
              </div>

              <div class="form-item">
                <label class="">
                  Number of employees
                </label>
                <select v-model="num_employee" class="form-input" placeholder="Number of employees" disabled>
                  <option value="" disabled selected hidden>Pick number of employees</option>
                  <option v-for="num_emp in categories" :value="num_emp" selected>{{ num_emp }}</option>
                </select>
              </div>
              <div class="form-item">
                <label class="">
                  Email address
                </label>
                <v-text-field v-model="email" :hide-details="emailErrors.isvalid" class="form-input"
                  placeholder="contact@spacekola.org" type="email" :error-messages="emailErrors.value"
                  @input="$v.email.$touch()" @blur="$v.email.$touch()" clearable disabled>
                </v-text-field>
              </div>
            </div>

            <div class="sm:col-span-12 md:col-span-6 text-white">
              <div class="form-item">
                <label class="">
                  Phone number
                </label>
                <vue-phone-number-input v-model="phonenumber" @update="onUpdate" :default-country-code="'CM'" class=""
                  disabled>
                </vue-phone-number-input>
              </div>
              <span class="error--text text-caption" v-if="phoneErrors.isvalid === false">{{
                phoneErrors.value
                }}</span>
              <div class="form-item">
                <label class="">
                  Website
                </label>
                <v-text-field v-model="website" dense hide-details placeholder="www.spacekola.org" class="form-input"
                  disabled></v-text-field>
              </div>

              <div class="form-item">
                <label class="">
                  Country location
                </label>
                <v-select v-model="selectedCountry" dense :items="countries" item-text="name" item-value="id"
                  class="form-input" virtual hide-details disabled>
                </v-select>
              </div>

              <div class="form-item">
                <label class="">
                  Address
                </label>
                <v-text-field v-model="address" dense hide-details placeholder="Company's adress" class="form-input"
                  disabled></v-text-field>
              </div>
              <div class="form-item">
                <label class="">
                  Enterprise description
                </label>
                <textarea v-model="about" class="form-input" placeholder="Your Name" disabled />
              </div>
            </div>

          </div>
        </v-form>

        <div class="action mt-8">
          <v-btn text color="error" @click="stagelevel === 2 ? goToPreviousStep() : backToList()">
            {{ stagelevel === 2 ? 'Back to form' : 'Cansel' }}
          </v-btn>
          <v-btn color="primary" @click="stagelevel === 2 ? submitCreateNewCompany() : submitStage(1)">
            {{ stagelevel === 2 ? 'Save' : 'Continue' }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiCamera } from "@mdi/js";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import { Drequest } from "@/plugins/Drequest";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import DatePicker from "@/layouts/components/microcomponents/DatePicker.vue";
import CardHeader from "@/views/dashboard/components/CardHeader.vue";

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, minLength: minLength(3) },
    about: { required, minLength: minLength(3) },
    email: { required, email }
  },
  data() {
    return {
      topheaders: {
        titlepage: "MY COMPANIES",
        icon: null
      },
      stagelevel: 1,
      loader: {
        submit: false,
        isCity: false
      },
      phoneCountry: {},
      phoneErrors: { isValid: true, value: "" },

      name: "",
      about: "",
      email: "",
      phonenumber: "",
      website: "",
      user_id: "",
      address: "",
      city: "",
      experienceyear: "",
      // application_market: '',
      enterprise_type_id: "",
      selectedCountry: 1,
      countries: [],
      found: {},
      annual_turnover: "",
      num_employee: "",

      userlogo: '',
      userbanner: '',

      mcoverfiles: null,
      mprofilfiles: null,
    };
  },
  components: {
    CardHeader,
    VuePhoneNumberInput,
    DatePicker
  },

  computed: {
    nameErrors() {
      const errors = { isvalid: true, value: [] };
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.value.push("Name must be at most 10 characters long") &&
        (errors.isvalid = false);
      !this.$v.name.required && errors.value.push("Name is required.") && (errors.isvalid = false);
      return errors;
    },

    aboutErrors() {
      const errors = { isvalid: true, value: [] };
      if (!this.$v.about.$dirty) return errors;
      !this.$v.about.minLength &&
        errors.value.push("Please describe your company") &&
        (errors.isvalid = false);
      !this.$v.about.required &&
        errors.value.push("Description is required.") &&
        (errors.isvalid = false);
      return errors;
    },
    emailErrors() {
      const errors = { isvalid: true, value: [] };
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.value.push("Must be valid e-mail") && (errors.isvalid = false);
      !this.$v.email.required &&
        errors.value.push("E-mail is required") &&
        (errors.isvalid = false);
      return errors;
    }
  },
  methods: {

    onBannerChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createBanner(files[0]);
    },
    onProfilChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        // this.loader.user.logo = false
        return;
      }
      // this.loader.user.logo = true
      this.createImage(files[0]);
    },
    createImage(file) {
      var userlogo = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.userlogo = e.target.result;
      };
      reader.readAsDataURL(file);

      this.mprofilfiles = file;

      // this.uploadUserlogo()
    },

    createBanner(file) {
      var userbanner = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.userbanner = e.target.result;
      };
      reader.readAsDataURL(file);

      this.mcoverfiles = file;
      // this.uploadUserBanner()
    },

    backToList() {
      this.$router.push({ name: "user.list.company" });
    },
    onUpdate(payload) {
      this.phoneCountry = payload;
      // this.selectedCountry = (this.countries.find((element) => element.iso === this.phoneCountry.countryCode)).id;
      if (payload.isValid === true) {
        this.phoneErrors.isValid = true;
        this.phoneErrors.value = "";
      } else {
        this.phoneErrors.isValid = false;
        this.phoneErrors.value = "Incorrect phone number";
      }
    },
    checkValidationForm(step) {
      return true;
      // if (step === 1) {
      //   if (this.nameErrors.isvalid && this.emailErrors.isvalid && this.phoneCountry.isValid) {
      //     return true;
      //   }
      //   return false;
      // }

      // if (step === 2) {
      //   if (this.nameErrors.isvalid && this.emailErrors.isvalid && this.phoneCountry.isValid) {
      //     return true;
      //   }
      //   return false;
      // }
    },
    toNextStep() {
      this.stagelevel = this.stagelevel + 1;
    },
    goToPreviousStep() {
      this.stagelevel = this.stagelevel - 1;
    },
    resetStepStage(stage) {
      if (stage === 1) {
        this.name = "";
        this.about = "";
        this.annual_turnover = "";
        this.num_employee = "";
      }
      if (stage === 2) {
        this.name = "";
        this.about = "";
        this.email = "";
        this.phonenumber = "";
        this.website = "";
        this.address = "";
        // this.created_at = ''
      }
    },

    submitStage(stage) {
      if (stage === 1) {
        this.$v.$touch();
        // if (this.nameErrors.isvalid && this.aboutErrors.isvalid) {
        if (this.nameErrors.isvalid && this.emailErrors.isvalid) {
          this.toNextStep();
        }
        // this.resetStepStage(2);
      }

      if (stage === 2) {
        this.$v.$touch();
        if (this.emailErrors.isvalid) {
          this.toNextStep();
        }
        // this.resetStepStage(2);
      }
    },
    // uploadUserlogo() {
    //   var formdatas = new FormData();
    //   let req_logo = `user.uploadlogo?user_id=${this.$store.getters.getUser.id}`
    //   formdatas.append("user_form[logo]", this.mprofilfiles);

    //   Drequest.api(req_logo)
    //     .data(formdatas)
    //     .post((response) => {
    //       this.$store.commit("setuserlogo", response.logo);
    //     }).catch((err) => {
    //     });
    // },
    submitCreateNewCompany() {
      this.$v.$touch();
      var bodyFormData = {
        enterprise: {
          name: this.name,
          website: this.website,
          email: this.email,
          telephone: this.phonenumber,
          about: this.about,
          "user.id": this.$store.getters.getUser.id,
          nb_employee: parseInt(this.num_employee),
          application_market: parseInt(this.annual_turnover),
          // logo: this.mprofilfiles
        }
      };

      var formdata = new FormData();
      // let req_logo = `user.uploadlogo?user_id=${this.$store.getters.getUser.id}`
      //   formdata.append("user_form[logo]", this.mprofilfiles);
      if (this.checkValidationForm()) {
        this.loader.submit = true;
        const detail = "";
        Drequest.api("enterprise.create")
          .data(bodyFormData)
          .raw(response => {
            if (response.success === true) {
              this.loader.submit = false;
              this.$store.commit("setCompany", {
                id: response.enterprise.id,
                about: response.enterprise.about,
                logo: response.enterprise.logo,
                name: response.enterprise.name,
                activity_sector: response.enterprise.activity_sector,
                email: response.enterprise.email,
                phonenumber: response.enterprise.phonenumber,
                website: response.enterprise.website,
                form: response.enterprise.enterprise_form,
                type: response.enterprise.enterprise_type
              });

              var formdatas = new FormData();
              // let req_logo = `enterprise.uploadlogo?user_id=${this.$store.getters.getCompany.id}`
              formdatas.append("enterprise_form[logo]", this.mprofilfiles);

              Drequest.api(`enterprise.uploadlogo?enterprise_id=${this.$store.getters.getCompany.id}`)
                .data(formdatas)
                .post((response) => {
                }).catch((err) => {
                });
              this.$fire({
                type: "success",
                text: "New enterprise created succefully.",
                timer: 2000
              });
              this.$router.push({ name: "user.list.company" });
            } else {
              this.loader.submit = false;
              this.$fire({
                type: "error",
                text: "Error during the creation of your company",
                timer: 2000
              });
            }
          })
          .catch(err => {
            console.log(err);
            this.$fire({
              type: "error",
              text: "Error during the creation of your company",
              timer: 2000
            });
            this.loader.submit = false;
          });
      }
    }
  },
  mounted() {
    Drequest.api(`lazyloading.country?dfilters=on&per_page=239`)
      .get(response => {
        if (response.success === true) {
          this.countries = response.listEntity;
        } else {
        }
      })
      .catch(err => { });
  },
  setup() {
    const icons = { mdiCamera };
    const categoridata = ["PME", "TPE", "Other"];
    const typedata = [
      "Financial nstitution",
      "Training institution",
      "Internationnal organization",
      "Research & development organization",
      "State agency",
      "ONG",
      "Chamber of commerce",
      "Others"
    ];

    const items_turnover = [
      "Smaller than USD 50,000",
      "Between USD 50,000 and 100,000",
      "Between USD 100,000 and 250,000",
      "Between USD 250,000 and 500,000",
      "Between USD 500,000 and 750,000",
      "Between USD 750,000 and 1,000,000",
      "More than USD 1 million",
      "More than USD 5 million",
      "More than USD 10 million"
    ],

      categories = [
        "1 to 10",
        "10 to 49",
        "50 to 249",
        "More than 250"
      ]
    //         const turnover_items = [
    // 'Institutions financières',
    // 'Institutions de formations',
    // 'Organisation internationnale',
    // 'Organisation de recherche & developpement',
    // 'Organisme d\'etat',
    // 'SME/ PME',
    // 'VSE/TPE',
    // 'Big company/ Grande Entreprise',
    // 'Multinational/ Multinationale',
    // 'Chamber of commerce/ Chambre de commerce',
    // 'Others/Autre, specify/spécifier']

    return {
      icons,
      typedata,
      items_turnover,
      categoridata,
      categories
    };
  }
};
</script>

<style lang="scss" scoped>
//@import 'vue-phone-number-input/dist/vue-phone-number-input.css';
@import "@/styles/styles.scss";
@import "@/styles/form-style.scss";


.form-enterprise {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  // border-radius: 0.8em;
  background-color: #fff;
  padding: 1em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  // border: solid 1px #000;

  width: 100%;
  // max-width: 1200px;


  .form {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    // border: solid 1px #000;
  }

  .form-item {
    background: transparent;
    width: 100%;
    // max-width: 100%;
    // min-width: 100%;
    max-width: 28em;
    min-width: 24em;
    // border: solid 1px #000;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      color: #4B5563;
      font-weight: 500;
      transition: color 0.3s ease;
    }

    label:hover {
      color: #1F1F1F;
    }

    .form-input {
      width: 100%;
      background: #EDF2FA;
      color: #1F1F1F;
      font-size: 0.875rem;
      border: 1px solid #E5E7EB;
      border-radius: 0.375rem;
      padding: 0.5rem 0.75rem;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

    /* Style pour le placeholder */
    .form-input::placeholder {
      color: #9CA3AF;
    }

    /* État de focus */
    .form-input:focus {
      outline: none;
      border-color: #60A5FA;
      box-shadow: 0.05rem rgba(96, 165, 250, 0.25);
    }

    /* État au hover */
    .form-input:hover {
      border-color: transparent;
      outline: none;
      box-shadow: 0.05rem rgba(96, 165, 250, 0.25);
    }

    textarea {
      min-height: 110px;
      resize: none;
    }
  }
}

.new-company-single-block {
  border-radius: 1em;
  //border: solid 2px var(--v-subprimary-base)
}

.form-2col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .form-2col-item {
    width: 49%;
  }
}
</style>
